import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import '../components/Print/Print.css';
import printJS from 'print-js'

const Product = () => {
    const location = useLocation();
    const data = location.state;
    const [loadingResults, setLoading] = useState(true); // your state value to manipulate
    const [loadingResultsDone, setLoadingDone] = useState(true); // your state value to manipulate
    const [loadingResultsDoneRedirect, setLoadingRedirectDone] = useState(false); // your state value to manipulate

    useEffect(()=>{
        printJS({
            printable: [data],
            // printable: data,
            type: 'image',
            header: '',
            style: '@page { size: auto;  margin: 0mm; } body {background-color: #ffff}',
            imageStyle: 'width:100%;max-width:200px;',
            scanStyles: true,  // The library will not process styles applied to the html being printed
            onLoadingStart:  function() {
                console.log('Functionality to run before printing.');
            },
            onLoadingEnd:  function() {
                console.log('Functionality to run after loading.');
            },
            onPrintDialogClose:  function() {
                console.log('Print is sent.');
                setLoading(false);
            },
        })
        if(loadingResults){        
            setTimeout(() => {
                setLoadingDone(false);
                setTimeout(() => {
                    setLoadingRedirectDone(true);
                }, 5000);
            }, 10000);
        }
    }, [setLoading, setLoadingDone, setLoadingRedirectDone]) // <-- empty dependency array

    if(loadingResultsDoneRedirect){
        return (<Navigate to="/"  />);
    } else{
        return (
            <div className='print-loader'>
                <div className='print-loader__icon-wrapper'>
    
                {loadingResultsDone ? (
                    <div className='print-loader__icon-loading'>
                        <span></span>
                        <h2>Perfection Takes A Moment.</h2>
                    </div>
                ) : (
                    <div className='print-loader__icon-success'>
                        <span></span>
                        <h2>Please Take Your Photo <br></br>And Get Back To Dancing.</h2>
                    </div>
                )}
                </div>
            </div>
        );
    }
}
export default Product;