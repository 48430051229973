import React from 'react';
import './index.css';



import reportWebVitals from './reportWebVitals';
import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import App from "./App";
import Photobooth from "./routes/photo-booth";
import PhotoboothPreview from "./routes/photo-booth-preview";
import PhotoboothPrint from "./routes/photo-booth-print";


const rootElement = document.getElementById("root");
render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="photo-booth" element={<Photobooth />} />
      <Route path="photo-booth-preview" element={<PhotoboothPreview />} />
      <Route path="photo-booth-print" element={<PhotoboothPrint />} />
    </Routes>
  </BrowserRouter>,
  rootElement
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
