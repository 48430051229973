// import Home from '../components/Home/Home'
import '../components/Webcam/Webcam.css';
import React from 'react'
import { WebcamCapture } from '../components/Webcam/Webcam'



export default function Photobooth() {
    return (
        <div className="photo-container">
            <div className="container">
                <div className="nav">
                    <span data-counter = "1" className='active'>3</span>
                    <span data-counter = "2">2</span>
                    <span data-counter = "3">1</span>
                </div>
                <WebcamCapture />
                <div className="icon"></div>
            </div>
            <div className="text">Smile for the newly weds</div>

        </div>
    );
}