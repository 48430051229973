import './App.css';
import { Link } from "react-router-dom";

function App() {
  return (
    <div className="App">
        <div className="start-screen">
          <div className="container">
            <div className='logo'></div>
            <div className='text'>Grab a prop <span>&</span> strike an awesome pose</div>
            <Link className='button' to="/photo-booth">START</Link>
          </div>
        </div>
    </div>
  );
}
export default App;